import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { ADMIN_ADEPT_DATA, ADMIN_TRAINING_TARGETS_MANAGE } from 'Consts/routes';
import { ACTIVITY_LOGS_DATA_TYPE_TRAINING_TARGET } from 'Consts/activityLogs';

import { withVariables } from 'Utils/string';

import StyledComponent from 'Components/core/StyledComponent';
import PageContent from 'Components/layout/panel/PageContent';
import Page from 'Components/layout/panel/Page';
import TabNavigation from 'Components/layout/panel/TabNavigation';
import Editor from 'Components/admin/trainingTargets/Editor';
import { TAB_TRAINING_TARGETS } from 'Components/pages/admin/AdeptData/component';
import LogsList from 'Components/admin/activityLogs/List';

export const TAB_DATA = 'data';
export const TAB_LOGS = 'logs';

export default class AdminTrainingTargetsManage extends Component {
    static propTypes = {
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
        match: PropTypes.shape({
            params: PropTypes.shape({
                id: PropTypes.string.isRequired,
            }).isRequired,
        }).isRequired,
        actions: PropTypes.shape({
            single: PropTypes.func.isRequired,
        }).isRequired,
        trainingTarget: PropTypes.shape({
            isLoading: PropTypes.bool.isRequired,
            isLoaded: PropTypes.bool.isRequired,
            data: PropTypes.object,
        }),
    };
    static defaultProps = {};

    componentDidMount = () => {
        this.loadData();
    }

    componentDidUpdate = prevProps => {
        const { match } = this.props;
        const { params } = match;

        if (prevProps.match.params.id !== params.id) {
            this.loadData();
        }
    }

    loadData = () => {
        const { actions, match } = this.props;
        const { params } = match;

        actions.single({ id: params.id });
    }

    render() {
        const { trainingTarget, location, history } = this.props;

        return (
            <StyledComponent
                className="admin-training-targets-manage"
                styles={require('./styles')}
            >
                <Page
                    elementMode={true}
                    data={trainingTarget}
                >
                    {trainingTarget && trainingTarget.data && (
                        <PageContent
                            breadcrumbs={[{
                                to: withVariables(ADMIN_ADEPT_DATA.path),
                                label: 'Kartoteka',
                            }, {
                                to: withVariables(ADMIN_ADEPT_DATA.path, {}, { tab: TAB_TRAINING_TARGETS }),
                                label: 'Cele treningowe',
                            }, {
                                to: withVariables(ADMIN_TRAINING_TARGETS_MANAGE.path, { id: trainingTarget.data.id }, { tab: TAB_DATA }),
                                label: `Edytuj cel treningowy: ${ trainingTarget.data.name }`,
                            }]}
                        >
                            <TabNavigation
                                location={location}
                                history={history}
                                headline="Edytuj cel treningowy"
                                tabs={[{
                                    key: TAB_DATA,
                                    label: 'Dane',
                                    children: (
                                        <Editor
                                            location={location}
                                            history={history}
                                            data={trainingTarget.data}
                                        />
                                    ),
                                }, {
                                    visible: true,
                                    key: TAB_LOGS,
                                    label: 'Logi',
                                    children: (
                                        <LogsList 
                                            location={location}
                                            history={history}
                                            predefinedQuery={{
                                                dataType: ACTIVITY_LOGS_DATA_TYPE_TRAINING_TARGET,
                                                dataId: trainingTarget.data.id || undefined,
                                            }}
                                            onMapFilters={() => []}
                                        />
                                    ),
                                }]}
                            />
                        </PageContent>
                    )}
                </Page>
            </StyledComponent>
        );
    }
}

